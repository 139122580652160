let $main = document.querySelector('main')
let $views = document.querySelectorAll('.view')
let $handleInput = document.querySelector('#handle-input')
let username = ''
let activeView = 'intro'

let isValidUsername = handle => {
  let regex = /^[a-zA-Z0-9_]*$/

  if (regex.test(handle)) {
    return true
  } else {
    return false
  }
}

let loop = (render, list) => {
  let html = ''

  list.forEach(item => {
    html += render(item)
  })

  return html
}

let getAllHashtagsForUser = username => {
  let url = 'https://hashtagme-api.bromy.co/api/users/' + username

  fetch(url)
    .then(response => response.json())
    .then(json => {
      if (json.hashtags[0] === undefined) {
        showView('none')
      } else {
        displayResults(json)
      }
    })
    .catch(error => {
      console.error(error)

      showView('error')
    })
}

let showView = view => {
  activeView = view

  $main.classList.add('is-switching')

  window.scroll(0, 0)
}

let displayResults = results => {
  try {
    // render most used hashtag
    let $mostUsedHashtag = $main.querySelector('.most-used-hashtag')
    let $totalTweets = $main.querySelector('.total-tweets')

    $mostUsedHashtag.href = `https://twitter.com/search?q=%23${results.hashtags[0].hashtag}`
    $mostUsedHashtag.textContent = `#${results.hashtags[0].hashtag}`
    $totalTweets.textContent = results.totalTweets

    // render top ten hashtags
    $main.querySelector('.top-hashtags').innerHTML = `
      ${loop(context => `
        <li>
          <a class="top-hashtags-item" href="https://twitter.com/search?q=%23${context.hashtag}" target="_blank">
            <span class="top-hashtags-rank"></span>
            <span class="top-hashtags-value">#${context.hashtag}</span>
            <span class="top-hashtags-count note">used ${context.count} times</span>
          </a>
        </li>
      `, results.hashtags.slice(0, 10))}
    `

    // render swag check
    let swagCheckMessage = results.swagCheck ?
      'You\'ve used the hashtag #swag before. Your tweets have swag.' :
      'The hashtag #swag was not found in your tweets. This is most likely a good thing.'

    results.swagCheck ?
      $main.querySelector('.swag-check-icon-true').style.display = 'block':
      $main.querySelector('.swag-check-icon-false').style.display = 'block'

    $main.querySelector('.swag-check-message').textContent = swagCheckMessage

    // render totals
    $main.querySelector('#totals .result').innerHTML = `
      <p class="totals"><span class="total">${results.totalUniqueHashtags}</span> unique hashtags</p>
      <p class="totals"><span class="total">${results.totalHashtags}</span> total hashtag uses</p>
      <p class="totals"><span class="total">${results.hashtagsPerTweet}</span> hashtags per Tweet</p>
    `

    // render share
    let shareBaseURL = 'https://twitter.com/share?url=https%3A%2F%2Fhashtagme.bromy.co%2F&hashtags=hashtagme&text='

    $main.querySelector('.button.share-yours').href =
      `${shareBaseURL}Looks like %23${results.hashtags[0].hashtag} is my most used hashtag:`

    $main.querySelector('.button.share-theirs').href =
      `${shareBaseURL}Hey %40${username}, looks like your most used hashtag is %23${results.hashtags[0].hashtag}:`


    showView('results');
  } catch (error) {
    console.error(error)

    showView('error');
  }
}

// handle main transition end
$main.addEventListener('transitionend', event => {

  // don't let other transitions bubble up
  if (event.target.nodeName !== 'MAIN') {
    return
  }

  $views.forEach(element => {
    element.style.display = 'none'
  })

  $main.querySelector('#' + activeView).style.display = 'block'

  $main.classList.remove('is-switching')
})

// handle again buttons
document.querySelectorAll('.button.again').forEach($button => {
  $button.addEventListener('click', event => {
    showView('intro')

    $handleInput.value = ''
  })
})

// handle search
$handleInput.addEventListener('keyup', event => {
  if (event.key === 'Enter') {
    // normalize username
    username =
      event.target.value
        .replace('@', '')
        .toLowerCase()
        .trim()

    if (isValidUsername(username)) {
      showView('loading')

      getAllHashtagsForUser(username)
    }
  }
})
